import { ArrowPathIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon, FunnelIcon, MoonIcon, TrashIcon } from '@heroicons/react/24/outline'
import React from 'react'
import DataTable from '../components/generic/DataTable'

export const Documents = () => {
  return (
    <div className='w-full h-screen'>
        <h1 className='text-3xl h-[10%] text-start py-10 px-16 flex items-center text-gray-800 font-semibold'>In Progress</h1>
        <div className='flex px-16 justify-between items-center'>
            <div className='flex space-x-5'>
                <div>
                    View 1 - 1 of 1
                </div>
                <div>
                    Show
                </div>
                <TrashIcon className='w-5 h-5' />
                <ArrowPathIcon className='w-5 h-5' />
                <MoonIcon className='w-5 h-5' />
            </div>
            <div className='flex space-x-5 items-center'>
                <ChevronDoubleLeftIcon className='w-5 h-5' />
                <ChevronLeftIcon className='w-5 h-5' />
                <input type="number" name="pageNo" id="" value={'5'} className='w-8 flex text-center border' />
                <ChevronRightIcon className='w-5 h-5' />
                <ChevronDoubleRightIcon className='w-5 h-5' />
                <span>|</span>
                <FunnelIcon className='w-5 h-5' />
            </div>
        </div>
        <DataTable people={[{documentName: 'Rahul', status: 'In Progress'}, {documentName: 'Goel', status: 'Completed'}]} />
    </div>
  )
}
