import { configureStore } from "@reduxjs/toolkit";
import FilesReducer from "./stores/FilesSlice";
import UserReducer from "./stores/UserStore";
import DocumentReducer from "./stores/DocumentSlice"
import AdminReducer from "./stores/AdminSlice"
import SuperAdminReducer from "./stores/SuperAdminSlice"

export const store = configureStore({
    reducer: {
        admin: AdminReducer,
        superAdmin: SuperAdminReducer,
        files: FilesReducer,
        user: UserReducer,
        document: DocumentReducer
    }
})
