import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { esignAPI, headers } from "../APIRoutes";

const initialState = {
    currentDocumentId: [],
    currentDocument: null
}

export const documentSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        setCurrentDocumentId: (state, action) => {
            state.currentDocumentId = action.payload
        },
        setCurrentDocument: (state, action) => {
            state.currentDocument = action.payload
        }
    }
})

export const { setCurrentDocumentId, setCurrentDocument } = documentSlice.actions
export default documentSlice.reducer
