import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { adminAPI, headers } from "../APIRoutes";

const initialState = {
    products: []
}

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setProducts: (state, action) => {
            state.products = action.payload
        }
    }
})

export const fetchProducts = (headers) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`${adminAPI}/getAllProducts`, { headers })
            if (response.data.message === 'SUCCESS') {
                dispatch(setProducts(response.data.response.productsName))
            }
            return response.data.message
        } catch (error) {
            
        }
    }
}

export const createUser = (postData, headers) => {
    return async () => {
        try {
            const response = await axios.post(`${adminAPI}/createUser`, postData, { headers })
            return response.data.message
        } catch (error) {
            
        }
    }
}

export const { setProducts } = adminSlice.actions
export default adminSlice.reducer
