import React, { useEffect, useState, useRef, useCallback } from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { BuildingOffice2Icon, CalendarDaysIcon, EnvelopeIcon, PencilIcon, PhotoIcon, ShareIcon, ArrowDownTrayIcon, PrinterIcon, ArrowsPointingOutIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { Fields } from '../components/docViewer/Fields';
import { LuStamp, LuRotateCcw, LuRotateCw } from "react-icons/lu";
import { TfiCheckBox } from "react-icons/tfi";
import { FaSignature } from "react-icons/fa6";
import { useSelector } from 'react-redux';
import SignatureModal from '../components/docViewer/SignatureModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { esignAPI } from '../APIRoutes';
import axios from 'axios';
import { TbTextRecognition } from 'react-icons/tb';
import { BsPersonStanding } from 'react-icons/bs';

export const DocViewer = () => {

    const fields = [
        { fieldId: '1', name: 'Signature', icon: PencilIcon },
        { fieldId: '2', name: 'Initial', icon: FaSignature },
        { fieldId: '3', name: 'Stamp', icon: LuStamp },
        { fieldId: '4', name: 'Image', icon: PhotoIcon },
        { fieldId: '5', name: 'Company', icon: BuildingOffice2Icon },
        { fieldId: '6', name: 'Email', icon: EnvelopeIcon },
        { fieldId: '7', name: 'Sign Date', icon: CalendarDaysIcon },
        { fieldId: '8', name: 'Checkbox', icon: TfiCheckBox },
        { fieldId: '9', name: 'Job Title', icon: TbTextRecognition },
        { fieldId: '10', name: 'Full Name', icon: BsPersonStanding },
    ]

    const [viewMode, setViewMode] = useState('edit')

    const [totalPages, settotalPages] = useState(0);
    const [pageNumber, setpageNumber] = useState(1);
    const [selectedFields, setSelectedFields] = useState([])
    const [openSignatureModal, setOpenSignatureModal] = useState(false)
    const [url, setUrl] = useState('')

    const documentId = useSelector(state => state.document.currentDocumentId)
    const userAuthToken = useSelector(state => state.user.userToken)

    const pageRefs = useRef([]);
    const observer = useRef(null); 
    const navigate = useNavigate()
    const location = useLocation()
    const fieldRefs = useRef([])
    const dragData = useRef({
      offsetX: 0,
      offsetY: 0
    });

    function handleOnDrop(e, pageNumber) {
        e.preventDefault();
      
        const fieldType = JSON.parse(e.dataTransfer.getData("fieldType"));
        const positions = JSON.parse(e.dataTransfer.getData("positions"));
        const pageRef = pageRefs.current[pageNumber];  // Get the ref for the page
        // Check if pageRef exists and is valid
        if (!pageRef) {
          console.error("Page reference is not available for page number:", pageNumber);
          return;
        }
      
        const rect = pageRef.getBoundingClientRect(); // Get the bounding box of the page
      
        if (fieldType.id) {
          const updatedFields = selectedFields.map((field) => {
            if (field.id === fieldType.id) {
              return {
                ...field,
                x: e.clientX - rect.left - positions.x - 0.5, // X relative to the page
                y: e.clientY - rect.top - positions.y - 0.5,  // Y relative to the page
                page: pageNumber // Ensure the field is set to the correct page
              };
            }
            return field;
          });
          setSelectedFields([...updatedFields]);
          return;
        }
      
        const selectedFieldType = fields.find((field) => field.fieldId === fieldType.fieldId);
        const selectedField = {
          ...selectedFieldType,
          id: selectedFields.length + 1,
          x: e.clientX - rect.left - positions.x - 0.5,  // X relative to the page
          y: e.clientY - rect.top - positions.y - 0.5,   // Y relative to the page
          page: pageNumber           // Store the page number the field is on
        };
      
        setSelectedFields([...selectedFields, selectedField]);
      }
      

    function handleDragOver(e) {
        e.preventDefault();
    }

    function handleOnDragStart(e, field, index) {
        const rect = fieldRefs.current[index].getBoundingClientRect();
        // Calculate the offset between the cursor and the field's top-left corner
        dragData.current.offsetX = e.clientX - rect.left;
        dragData.current.offsetY = e.clientY - rect.top;
        
        e.dataTransfer.setData("fieldType", JSON.stringify(field));
        e.dataTransfer.setData("positions", JSON.stringify({x: dragData.current.offsetX , y: dragData.current.offsetY }))
    }

    function removeField(field) {
        const fields = selectedFields.filter((fieldType) => fieldType.id !== field.id)
        setSelectedFields([...fields])
    }

    function showSpecificModals(fieldType) {
        if (fieldType === 'Signature') setOpenSignatureModal(true)
    }

    function closeSignatureModal() {
        setOpenSignatureModal(false)
    }

    const scrollToPage = (pageIndex) => {
        const page = pageRefs.current[pageIndex];
        if (page) {
            page.scrollIntoView({ behavior: 'smooth' });
            setpageNumber(pageIndex)
        }
    };

    // Function to observe when a page is in view and update the current page number
    const observePages = useCallback(() => {
        if (observer.current) {
            observer.current.disconnect();  // Disconnect the old observer
        }


        // Create a new IntersectionObserver
        observer.current = new IntersectionObserver(
            (entries) => {
                const visiblePage = entries.find((entry) => entry.isIntersecting);
                if (visiblePage) {
                    const pageIndex = pageRefs.current.indexOf(visiblePage.target);
                    setpageNumber(pageIndex);  // Update current page number
                }
            },
            {
                threshold: 0.45,  // Trigger when 60% of the page is visible
            }
        );

        // Observe all pages
        pageRefs.current.forEach((page) => {
            if (page) observer.current.observe(page);
        });
    }, []);

    /**
     *
     * @param {Object} event
     *
     * This function will be called when pdf is loaded
     */
    function onDocLoad(event) {
        settotalPages(event.numPages);
    }

    useEffect(() => {
        // On load adding pdfjs worker
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        
        observePages();
    }, [observePages, totalPages]);

    const fetchDocument = async () => {
        try {
          const response = await fetch(`${esignAPI}/getDocument?documentId=${location.search.split('?docId=')[1]}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/pdf',
              AuthToken: userAuthToken, // Modify based on your API's response type
            },
          });
          
          // Convert response to ArrayBuffer
          const data = await response.arrayBuffer();

          // Create a Blob from the ArrayBuffer
          const blob = new Blob([data], { type: 'application/pdf' });
          
          // Create an object URL from the Blob
          const blobUrl = URL.createObjectURL(blob);
          setUrl(blobUrl)
        } catch (e) {
        }
    }

    useEffect(() => {
        if (!location.search.split('?docId=')[1]) navigate('/signYourself')
        else fetchDocument()
    }, [location, userAuthToken])

    return (
        <div className='overflow-hidden'>
            {openSignatureModal && <SignatureModal closeModal={() => closeSignatureModal()} />}
            <div className='bg-[#353535] py-4 px-10 flex items-center justify-between text-white'>
                <div className="flex items-center justify-center font-semibold text-lg">
                    Pdf File Name
                    <PencilIcon className='w-5 h-5 ml-3' />
                </div>
                <div className="flex justify-center items-center gap-1">
                    {`${pageNumber} / ${totalPages}`}
                </div>
                <div className='flex space-x-5'>
                    <button className="bg-slate-200 text-black px-6 cursor-pointer py-2 rounded-lg">
                        Save & Close
                    </button>
                    <button className="bg-green-600 text-white px-6 cursor-pointer py-2 rounded-lg">
                        Continue
                    </button>
                </div>
            </div>
            <div className='flex py-4 items-center px-10 justify-between'>
                <div className='flex space-x-5'>
                    <LuRotateCcw className='w-6 h-6' />
                    <LuRotateCw className='w-6 h-6' />
                    <PencilIcon className='w-6 h-6' />
                    <ArrowDownTrayIcon className='w-6 h-6' />
                    <PrinterIcon className='w-6 h-6' />
                    <ArrowsPointingOutIcon className='w-6 h-6' />
                    <span className='text-2xl -mt-1 flex justify-start items-start'>T</span>
                </div>
                <div>
                    <ShareIcon className='w-5 h-5' />
                </div>
            </div>
            <div className=" w-full h-screen flex justify-start items-start overflow-hidden">
                <div>
                     <Fields viewMode={viewMode} />
                </div>
                <div className="w-full h-full">
                    <div className="w-full bg-slate-100 h-full">
                        <div className="w-full bg-slate-100 p-4 h-full overflow-auto flex justify-center items-start">
                            <Document file={url}>
                                {Array.from({ length: totalPages }, (el, index) => (
                                    <Page key={`page_${index + 1}`} pageNumber={index + 1} pageIndex={index + 1} height={1500} width={1100} onDrop={(e) => handleOnDrop(e, index + 1)} onDragOver={handleDragOver} inputRef={el => pageRefs.current[index + 1] = el} className="relative my-4">
                                        {
                                            selectedFields.map((field) => {
                                                return (
                                                    <div
                                                        key={field.fieldId}
                                                        className="flex justify-between border-dashed border-2 border-green-500 bg-green-500/30"
                                                        onClick={() => {showSpecificModals(field.name)}}
                                                        style={{
                                                        position: 'absolute',
                                                        left: `${field.x}px`,
                                                        top: `${field.y}px`,
                                                        }}
                                                    >
                                                        {
                                                            index + 1 === field.page && <div className='flex relative justify-center items-center !cursor-move min-w-52 min-h-12 z-10 !group' draggable={viewMode === 'edit'} onDragStart={(e) => handleOnDragStart(e, field, index)} ref={el => fieldRefs.current[index] = el}>
                                                                {viewMode === 'edit' && <span className='absolute -right-2 -top-2 bg-red-500 rounded-full cursor-pointer text-white p-0.5' onClick={() => removeField(field)} draggable="false">
                                                                    <XMarkIcon className='w-4 h-4' />
                                                                </span>}
                                                                <span className='px-3 py-1.5 w-full h-full text-lg flex items-center justify-center'>{field.name}</span>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </Page>
                                ))}
                            </Document>
                        </div>
                    </div>
                </div>
                <div className="border-r-2 border-gray-400 w-96 py-2 h-full">
                    <div className="px-2 py-3 border-b-2 text-center font-semibold text-lg">
                        Documents
                    </div>
                    <div className="h-full pb-20">
                        <Document
                            className={
                                "flex flex-col justify-start items-center overflow-auto h-full"
                            }
                            file={url}
                            onLoadSuccess={onDocLoad}
                        >
                            {Array(totalPages)
                                .fill()
                                .map((_, index) => (
                                    <div
                                    key={index}
                                    onClick={() => scrollToPage(index + 1)} // Fix page number here
                                    className={`border-[4px] cursor-pointer relative rounded my-2 ${pageNumber === index + 1 ? "border-green-700" : ""}`}
                                    >
                                    <Page height={250} pageNumber={index + 1} />
                                    </div>
                                ))}
                        </Document>
                    </div>
                </div>
            </div>
        </div>
    )
}
