import React, { useRef, useState, useEffect } from 'react';

const DrawCanvas = ({ height, width, textColor, title }) => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [context, setContext] = useState(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.lineWidth = 5;
    ctx.lineCap = 'round';
    ctx.strokeStyle = `${textColor}`;
    setContext(ctx);
  }, []);

  const startDrawing = (e) => {
    context.beginPath();
    context.moveTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    setIsDrawing(true);
  };

  const draw = (e) => {
    if (!isDrawing) return;
    context.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    context.stroke();
  };

  const endDrawing = () => {
    context.closePath();
    setIsDrawing(false);
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    context.clearRect(0, 0, canvas.width, canvas.height); // Clear the entire canvas
  };

  useEffect(() => {
    if (context) {
        context.strokeStyle = textColor; // Update stroke color when color changes
    }
  }, [textColor, context])

  return (
    <div className='mt-6'>
        <div className='flex justify-between items-end my-3 px-2'>
            <h4 className='text-lg'>{title}</h4>
            <h5 onClick={clearCanvas} className='cursor-pointer text-sm text-blue-900'>Clear</h5>
        </div>
        <canvas
          ref={canvasRef}
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={endDrawing}
          onMouseLeave={endDrawing}
          width={width}
          height={height}
          className='rounded-lg'
          style={{ border: '1px solid black' }}
        />
    </div>
  );
};

export default DrawCanvas;
