import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { host } from "../APIRoutes";
import Cookies from "js-cookie";

const initialState = {
    loggedIn: [],
    userToken: "",
    userRole: ""
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoggedInStatus: (state, action) => {
            state.loggedIn = action.payload
        }, 
        setToken: (state, action) => {
            state.userToken = action.payload
        },
        setUserRole: (state, action) => {
            state.userRole = action.payload
        }
    }
})

export const regenerateToken = (token) => {
    return async (dispatch) => {
        try {
            const headers = { 'AuthToken': token }
            const response = await axios.get(`${host}/regenerate-token`, { headers } )
            if (response.data.ErrorMessage) return 'LOGIN'
            else if (response.data.response['RegeneratedToken']) { 
                dispatch(setToken(response.data.response['RegeneratedToken']));      
                Cookies.set('AuthToken', response.data.response['RegeneratedToken'], { expires: 7 }); // Set a cookie with a 7-day expiration
                return 'SUCCESS'; 
            }
        } catch (error) {
            
        }
    }
}

export const logoutUser = (headers) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`${host}/logout`, { headers })
            return response.data
        } catch (error) {
            
        }
    }
}

export const { setLoggedInStatus, setToken, setUserRole } = userSlice.actions
export default userSlice.reducer