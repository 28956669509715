import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { superAdminAPI, headers } from "../APIRoutes";

const initialState = {
    products: []
}

export const superAdminSlice = createSlice({
    name: 'superAdmin',
    initialState,
    reducers: {
        setProducts: (state, action) => {
            state.products = action.payload
        }
    }
})

export const createAdmin = (postData, headers) => {
    return async () => {
        try {
            const response = await axios.post(`${superAdminAPI}/createAdmin`, postData, { headers })
            return response.data.message ? response.data.message : response
        } catch (error) {
            
        }
    }
}

export const { setProducts } = superAdminSlice.actions
export default superAdminSlice.reducer
