import React, { useEffect } from 'react'
import Title from '../../components/generic/Title'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProducts } from '../../stores/AdminSlice'
import AdminDetails from '../../components/superAdmin/AdminDetails'

const CreateAdmin = () => {
    // const authToken = useSelector(state => state.user.userToken)
    // const dispatch = useDispatch()

    // useEffect(() => {
    //     if (authToken) {
    //         dispatch(fetchProducts({AuthToken: authToken}))
    //     }
    // }, [authToken])

  return (
    <div className='mt-16 w-full'>
      <Title title="Create Admin" />
      <AdminDetails />
    </div>
  )
}

export default CreateAdmin
