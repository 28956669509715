import React, { useEffect } from 'react'
import Title from '../../components/generic/Title'
import UserDetails from '../../components/admin/UserDetails'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProducts } from '../../stores/AdminSlice'

const CreateUser = () => {
    const authToken = useSelector(state => state.user.userToken)
    const dispatch = useDispatch()

    useEffect(() => {
        if (authToken) {
            dispatch(fetchProducts({AuthToken: authToken}))
        }
    }, [authToken])

  return (
    <div className='mt-16 w-full'>
      <Title title="Create User" />
      <UserDetails />
    </div>
  )
}

export default CreateUser
