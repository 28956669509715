import React, { useRef, useState } from 'react';
import { Recipients } from '../components/docViewer/Recipients';
import { BuildingOffice2Icon, CalendarDaysIcon, EnvelopeIcon, PencilIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { LuStamp } from "react-icons/lu";
import { TfiCheckBox } from "react-icons/tfi";
import { FaSignature } from "react-icons/fa6";

export const Test = ({ viewMode='edit' }) => {

  const fields = [
    { fieldId: '1', name: 'Signature', icon: PencilIcon },
    { fieldId: '2', name: 'Initial', icon: FaSignature },
    { fieldId: '3', name: 'Stamp', icon: LuStamp },
    { fieldId: '4', name: 'Image', icon: PhotoIcon },
    { fieldId: '5', name: 'Company', icon: BuildingOffice2Icon },
    { fieldId: '6', name: 'Email', icon: EnvelopeIcon },
    { fieldId: '7', name: 'Sign Date', icon: CalendarDaysIcon },
    { fieldId: '8', name: 'Checkbox', icon: TfiCheckBox }
  ];

  const [fieldss, setFieldss] = useState([])

  const fieldRefs = useRef([]);
  const dragData = useRef({
    offsetX: 0,
    offsetY: 0
  });

  function handleOnDragStart(e, field, index) {
    const rect = fieldRefs.current[index].getBoundingClientRect();
    // Calculate the offset between the cursor and the field's top-left corner
    dragData.current.offsetX = e.clientX - rect.left;
    dragData.current.offsetY = e.clientY - rect.top;
    
    e.dataTransfer.setData("fieldType", JSON.stringify(field));
  }

  function handleOnDrop(e) {
    e.preventDefault();
    const field = JSON.parse(e.dataTransfer.getData("fieldType"));

    // Get the drop coordinates and adjust using the offset
    const dropX = e.clientX - dragData.current.offsetX;
    const dropY = e.clientY - dragData.current.offsetY;

    // Here you would create the dropped element in its final position

    setFieldss([...fieldss, { x: dropX, y: dropY }])
    
    // You might want to set the state to reflect the new position of the element
    // or add the element to the target drop zone.
  }

  function handleOnDragOver(e) {
    e.preventDefault(); // Necessary to allow the drop
  }

  return (
    <div className='w-96'>
      <Recipients />
      {viewMode === 'edit' && <div>
        <h3 className='text-2xl text-start py-4 px-8 border-b-2 border-gray-300'>
          Fields
        </h3>
        <div className='py-4 px-4 grid grid-cols-2 gap-3'>
          {
            fields.map((field, index) => {
              return (
                <div
                  key={field.fieldId}
                  draggable
                  onDragStart={(e) => handleOnDragStart(e, field, index)}
                  className='flex cursor-move bg-white justify-between border'
                  ref={el => fieldRefs.current[index] = el}>
                  <span className='px-3 py-1.5'>{field.name}</span>
                  <span className='flex justify-center w-10 items-center bg-blue-700'>
                    <field.icon className='h-5 w-5 text-white' />
                  </span>
                </div>
              );
            })
          }
        </div>
      </div>
      }
      
      {/* Add a drop target area */}
      <div
        className='border-2 bg-red-400'
        onDrop={handleOnDrop}
        onDragOver={handleOnDragOver}
        style={{ width: '100%', height: '500px', backgroundColor: 'lightgray', marginTop: '20px' }}
      >
        Drop fields here
        {
                                            fieldss.map((field) => {
                                                return (
                                                    <div
                                                        key={field.fieldId}
                                                        className="flex justify-between border"
                                                        style={{
                                                        position: 'absolute',
                                                        left: `${field.x}px`,
                                                        top: `${field.y}px`,
                                                        }}
                                                    >
                                                        position
                                                    </div>
                                                )
                                            })
                                        }
      </div>
    </div>
  );
};
