import React from 'react'

export const UploadArea = ({ title, height, width }) => {
  return (
    <div className='mt-6'>
        <div className='flex justify-between items-end my-3 px-2'>
            <h4 className='text-lg'>{title}</h4>
            <h5 className='cursor-pointer text-sm text-blue-900'>Clear</h5>
        </div>
        <div style={{height: `${height}px`, width: `${width}px`}} className='flex flex-col space-y-3 bg-gray-200 rounded-lg items-center justify-center'>
            <span className='text-2xl font-medium text-gray-400'>Drag and Drop</span>
            <span className='text-lg text-gray-400'>OR</span>
            <button className='bg-gray-500/80 text-white rounded-lg py-1.5 font-medium px-4'>Choose Image</button>
        </div>
    </div>
  )
}
