import React from 'react'

export const Recipients = () => {
  return (
    <div className='py-4 flex flex-col items-start px-8 border-b-2 border-gray-300'>
      <h3 className='text-2xl text-gray-600 font-medium'>
        Recipients
      </h3>
      <p className='text-start pt-4'>
        Select a recipient for whom you need to add or request data:
      </p>
    </div>
  )
}
