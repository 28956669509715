import { Field, Select } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

export default function SelectBox({ list }) {
  return (
    <div className="w-96">
      <Field>
        <div className="relative">
          <Select
            className=
              'block w-full appearance-none bg-white border border-gray-300 rounded-lg py-2 px-3 text-sm/6 focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2'
          >
            {
              list.map((item) => {
                return (
                  <option key={item.id} value="active" className='p-2 my-5'>{item.name}</option>
                )
              })
            }

          </Select>
          <ChevronDownIcon
            className="group pointer-events-none absolute top-2.5 right-2.5 size-4"
            aria-hidden="true"
          />
        </div>
      </Field>
    </div>
  )
}
