export const host = 'https://uatapi.nodeapis.com'

export const headers = {
    'Content-Type': 'multipart/form-data',
    'AuthToken': "eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6IlNVUEVSQURNSU4iLCJzdWIiOiJra25pdGhpc2gxOTk5QGdtYWlsLmNvbSIsImlhdCI6MTcyNzA5MTM4NSwiZXhwIjoxNzI3MDkzMTg1fQ.mhhW_PQuD3Pm3RyRpEy63SP6XCw_2kht4uDyHZnsw6I"
}

export const adminAPI = `${host}/admin`
export const superAdminAPI = `${host}/sadmin`
export const usersAPI = `${host}/user`
export const esignAPI = `${host}/user/esign`
export const loginAPI = `${host}/auth/login`
