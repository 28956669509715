import logo from './logo.svg';
import './App.css';
import { Sidebar } from './components/homepage/Sidebar'
import { DocViewer } from './pages/DocViewer';
import { HomePage } from './pages/HomePage';
import { SignYourself } from './pages/SignYourself';
import { BrowserRouter as Router, Routes as Switch, Route, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Documents } from './pages/Documents';
import PDFEditor from './pages/PDFEditor';
import Login from './pages/Login';
import { Test } from './pages/Test';
import CreateUser from './pages/Admin/CreateUser';
import TopNavbar from './components/homepage/TopNavbar';
import { useSelector } from 'react-redux';
import CreateAdmin from './pages/SuperAdmin/CreateAdmin';

function App() {
  const role = useSelector(state => state.user.userRole)
  return (
    <div className="App flex">
      <Router>
        <TopNavbar />
        {role === 'USER' && <Sidebar />}
        <Switch>
          <Route exact path='/' element={<HomePage />} />
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/signYourself' element={<SignYourself />} />
          <Route exact path='/documentViewer' element={<DocViewer />} />
          <Route exact path='/documents' element={<Documents />} />
          <Route exact path='/pdfeditor' element={<PDFEditor />} />
          <Route exact path='/admin/createUser' element={<CreateUser />} />
          <Route exact path='/superAdmin/createAdmin' element={<CreateAdmin />} />
          <Route exact path='/test' element={<Test />} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
