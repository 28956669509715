import React, { useState } from 'react';
import Draggable from 'react-draggable';
import PdfPreview from '../components/PdfPreview';

const PDFEditor = () => {
    const [fields, setFields] = useState([]);

    const addField = (type) => {
        const newField = {
            type,
            id: Date.now(),
            x: 0,
            y: 0,
        };
        setFields([...fields, newField]);
    };

    return (
        <div>
            <div>
                <button onClick={() => addField('signature')}>Add Signature</button>
                <button onClick={() => addField('date')}>Add Date</button>
            </div>

            <div style={{ position: 'relative' }}>
                <PdfPreview fileUrl="../pdf/Design_Procedure_of_a_Topologically_Opti.pdf" />

                {/* Render draggable fields */}
                {fields.map((field) => (
                    <Draggable key={field.id} defaultPosition={{ x: field.x, y: field.y }}>
                        <div
                            style={{
                                position: 'absolute',
                                padding: '10px',
                                border: '1px solid black',
                                backgroundColor: 'lightgrey',
                            }}
                        >
                            {field.type === 'signature' ? 'Signature' : 'Date'}
                        </div>
                    </Draggable>
                ))}
            </div>
        </div>
    );
};

export default PDFEditor;
