import React, { useRef } from 'react'
import { Recipients } from './Recipients'
import { BuildingOffice2Icon, CalendarDaysIcon, EnvelopeIcon, PencilIcon, PhotoIcon } from '@heroicons/react/24/outline'
import { LuStamp } from "react-icons/lu";
import { TfiCheckBox } from "react-icons/tfi";
import { FaSignature } from "react-icons/fa6";
import { BsPersonStanding } from "react-icons/bs";
import { TbTextRecognition } from "react-icons/tb";

export const Fields = ({ viewMode }) => {

  const fields = [
    { fieldId: '1', name: 'Signature', icon: PencilIcon },
    { fieldId: '2', name: 'Initial', icon: FaSignature },
    { fieldId: '3', name: 'Stamp', icon: LuStamp },
    { fieldId: '4', name: 'Image', icon: PhotoIcon },
    { fieldId: '5', name: 'Company', icon: BuildingOffice2Icon },
    { fieldId: '6', name: 'Email', icon: EnvelopeIcon },
    { fieldId: '7', name: 'Sign Date', icon: CalendarDaysIcon },
    { fieldId: '8', name: 'Checkbox', icon: TfiCheckBox },
    { fieldId: '9', name: 'Job Title', icon: TbTextRecognition },
    { fieldId: '10', name: 'Full Name', icon: BsPersonStanding },
  ]

  const fieldRefs = useRef([])
  const dragData = useRef({
    offsetX: 0,
    offsetY: 0
  });

  function handleOnDragStart(e, field, index) {
    const rect = fieldRefs.current[index].getBoundingClientRect();
    // Calculate the offset between the cursor and the field's top-left corner
    dragData.current.offsetX = e.clientX - rect.left;
    dragData.current.offsetY = e.clientY - rect.top;
    
    e.dataTransfer.setData("fieldType", JSON.stringify(field));
    e.dataTransfer.setData("positions", JSON.stringify({x: dragData.current.offsetX , y: dragData.current.offsetY }))
  }

  return (
    <div className='w-96'>
      <Recipients />
      {viewMode === 'edit' && <div>
        <h3 className='text-2xl text-start py-4 px-8 border-b-2 border-gray-300'>
          Fields
        </h3>
        <div className='py-4 px-4 grid grid-cols-2 gap-3'>
          {
            fields.map((field, index) => {
              return (
                <div key={field.fieldId} draggable onDragStart={(e) => handleOnDragStart(e, field, index)} className='flex cursor-move bg-white justify-between border' ref={el => fieldRefs.current[index] = el}>
                  <span className='px-3 py-1.5'>{field.name}</span>
                  <span className='flex justify-center w-10 items-center bg-blue-700'>
                    <field.icon className='h-5 w-5 text-white' />
                  </span>
                </div>
              )
            })
          }
        </div>
      </div>
      }
    </div>
  )
}
