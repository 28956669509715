import { useEffect, useRef, useState } from "react"
import { DocumentTextIcon } from "@heroicons/react/20/solid";
import { LuLoader2 } from "react-icons/lu";

export default function DataTable({ people, selectedList, tableName, loading }) {

    const [csvData, setCsvData] = useState([])
    const [columnNames, setColumnNames] = useState([])
    const [tableData, setTableData] = useState([])
    const [fileName, setFileName] = useState('')

    const tableRef = useRef()

    const convertNameToUpperCase = (name) => {
        let convertedName = ''
        for (let i = 0; i < name.length; i++) {
            if (name[i] >= 'A' && name[i] <= 'Z') {
                convertedName += ` ${name[i]}`
            } else {
                if (i === 0) {
                    convertedName += name[i].toLocaleUpperCase()
                } else {
                    convertedName += name[i]
                }
            }
        }
        return convertedName
    }

    useEffect(() => {
        if (people.length > 0) {
            const data = people.map((person) => {
                return Object.values(person)
            });
            const heading = Object.keys(people[0])
            setCsvData([heading, ...data])
            const columns = Object.keys(people[0]).map((person) => {
                return {
                    itemName: convertNameToUpperCase(person),
                    show: true
                }
            })
            setColumnNames(columns)
            const newData = people.map((per) => {
                return Object.fromEntries(Object.entries(per).map((p) => {
                    p[0] = convertNameToUpperCase(p[0])
                    return p
                }))
            })
            setTableData(newData)
        } else setTableData([])
    }, [people])

    useEffect(() => {
        setFileName(`${tableName}-${new Date().getDate() + '-' + (new Date().getMonth() + 1)  +'-' + new Date().getFullYear()}`)
    }, [tableName])

    return (
        <div className="px-4 sm:px-6 lg:px-16">
            {
                tableData.length > 0 ? <div>
                    <div className="mt-3 overflow-auto ring-0 ring-white mx-0 rounded-lg" ref={tableRef}>
                        <table className="min-w-full">
                            <thead className="bg-gray-200">
                                <tr className="py-3.5 rounded-xl">
                                    <th className="w-10 pt-2"><input type="checkbox" name="" id="" className="w-4 h-4" /></th>
                                    {Object.keys(tableData[0]).map((key, index) => (
                                        <th key={key} className={`py-3.5 pl-2 pr-1 text-left text-sm font-semibold text-gray-900 sm:pl-4 ${index !== tableData.length - 1 && 'border-r-2 border-gray-400'}`}>{key}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className=" bg-white">
                                
                                {tableData.map((item, index) => (
                                    <tr key={index}>
                                        <th className="w-3 pt-1.5"><input type="checkbox" name="" id="" className="w-3.5 h-3.5" /></th>
                                        {Object.entries(item).map(([key, value], i) => (
                                            <th key={i} scope="col" className={`py-3.5 pl-3 pr-1 text-left text-sm font-semibold text-gray-900 sm:pl-4 ${key === 'Document Name' && 'cursor-pointer hover:underline'}`} onClick={() => key === 'Document Name' && selectedList(item)}>{value}</th>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div> : <div className="-mx-4 mt-3 space-x-4 flex justify-center items-center overflow-auto shadow ring-1 py-32 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                    {loading ? <div className="flex justify-center items-center space-x-4">
                        <LuLoader2 className='animate-spin w-6 h-6 ml-3'/>
                        <span>
                            Loading Data
                        </span>
                    </div> : <div  className="flex justify-center items-center space-x-4">
                        <DocumentTextIcon className="w-8 h-8 text-gray-400"/>
                        <span className="text-xl text-gray-500">
                            No Data Found
                        </span>
                    </div>
                    }
                </div>
            }
        </div>
    )
}