import axios from "axios"
import { useEffect, useState } from "react"
import { loginAPI } from "../APIRoutes"
import { useDispatch, useSelector } from "react-redux"
import { setToken, setUserRole } from "../stores/UserStore"
import { useNavigate } from "react-router-dom"
import Cookies from "js-cookie"
import { decodeJWT } from "../utils/helper"

export default function Login() {

    const [credentials, setCredentials] = useState({ email: '', password: '' })
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const role = useSelector(state => state.user.userRoles)

    const userAuthToken = useSelector(state => state.user.userToken)

    const handleChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const response = await axios.post(loginAPI, { Email: credentials.email, Password: credentials.password })
        if (response.data.message === 'SUCCESS') {
            const token = response.data.response.token
            const decodedJWT = decodeJWT(token)
            dispatch(setToken(token))
            dispatch(setUserRole(decodedJWT.roles))
            Cookies.set('AuthToken', token, { expires: 7 }); // Set a cookie with a 7-day expiration
            if (decodedJWT.roles === 'ADMIN') {
              navigate('/admin/createUser')
            } else if (decodedJWT.roles === 'USER') {
              navigate('/')
            } else if (decodedJWT.roles === 'SUPERADMIN') {
              navigate('/superAdmin/CreateAdmin')
            }
        }
    }

    useEffect(() => {
        if (userAuthToken) {
          if (role === 'ADMIN') {
            navigate('/admin/createUser')
          } else if (role === 'USER') {
            navigate('/')
          }
        }
    }, [userAuthToken, role])

    return (
      <>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              alt="Your Company"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
              className="mx-auto h-10 w-auto"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Sign in to your account
            </h2>
          </div>
  
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    autoComplete="email"
                    value={credentials.email}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
  
              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                  </label>
                  <div className="text-sm">
                    <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                      Forgot password?
                    </a>
                  </div>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    value={credentials.password}
                    onChange={handleChange}
                    autoComplete="current-password"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
  
              <div>
                <button
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={(e) => handleSubmit(e)}
                >
                  Sign in
                </button>
              </div>
            </div>
  
            <p className="mt-10 text-center text-sm text-gray-500">
              Not a member?{' '}
              <a href="#" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                Start a 14 day free trial
              </a>
            </p>
          </div>
        </div>
      </>
    )
  }
  