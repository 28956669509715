import { jwtDecode } from 'jwt-decode';

export function checkValidityOfToken(token) {
    if (!token) return "LOGIN";

    // Decode the token without verifying the signature
    const decoded = jwtDecode(token.toString());

    // Check if the token has an "exp" claim
    if (decoded && decoded.exp) {
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    
        if (decoded.exp < currentTime) {
            return "LOGIN"
        } else if (decoded.exp - 600 < currentTime) {
            return "REGENERATE"
        } else {
            return "VALID"
        }
    } else {
        console.log('Token does not have an expiration date.');
    }
}

export function decodeJWT(token) {
    return jwtDecode(token.toString())
}
