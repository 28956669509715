import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { esignAPI, headers } from "../APIRoutes";

const initialState = {
    uploadedDocuments: []
}

export const filesSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        setUploadedDocument: (state, action) => {
            state.uploadedDocuments = action.payload
        }
    }
})

export const uploadDocument = (postData) => {
    return async (dispatch) => {
        try {
            const response = axios.post(`${esignAPI}/uploadDocument`, postData, { headers } )
        } catch (error) {
            
        }
    }
}

export const { setUploadedDocument } = filesSlice.actions
export default filesSlice.reducer
